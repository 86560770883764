import React, { useState } from 'react';
import Header from '../components/layout/header.jsx';
import backgroundImage from "./../assets/img/wallpaper_home.webp";
import { Link } from "react-router-dom";

/* Images des produits */
import AllScript from "./../assets/img/scripts/AllScripts_Minia.webp";
import SquareMenu from "./../assets/img/scripts/SquareMenu_Minia.webp";
import BoutiqueV2 from "./../assets/img/scripts/BoutiqueV2_Minia.webp";
import Clothing from "./../assets/img/scripts/Clothing_Minia.webp";
import AgentImmo from "./../assets/img/scripts/AgentImmo_Minia.webp";
import Boutique from "./../assets/img/scripts/Boutique_Minia.webp";
import Connect from "./../assets/img/scripts/Connect_Minia.webp";
import Largage from "./../assets/img/scripts/Largage_Minia.webp";
import Loading from "./../assets/img/scripts/Loading_Minia.webp";
import LocationV2 from "./../assets/img/scripts/LocationV2_Minia.webp";
import PauseMenu from "./../assets/img/scripts/PauseMenu_Minia.webp";
import Police from "./../assets/img/scripts/Police_Minia.webp";
import Society from "./../assets/img/scripts/Society_Minia.webp";
import ShopV2 from "./../assets/img/scripts/ShopV2_Minia.webp";

export const products = [
    {
        title: "Memory's All Script",
        desc: "Package Memory's",
        desc2: "&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp- mSquareMenu https://www.youtube.com/watch?v=_t1UwndTugo<br />&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp- bBoutiqueV2 : https://www.youtube.com/watch?v=fzffolnbT5U<br />&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp- bPolice : https://youtu.be/nu3V5NnE6Io<br />&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp- bClothing : https://www.youtube.com/watch?v=bjB45fP9FoE<br />&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp- bBoutique : https://memory-s.tebex.io/package/5217672<br />&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp- bLoading : https://youtu.be/y-SwfCcaQuQ<br />&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp- bLocationV2 :https://youtu.be/EeMz6sw8jXE<br />&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp- bPauseMenu : https://youtu.be/F8x1j2yyqIA<br />&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp- bLargage : https://www.youtube.com/watch?v=3toKeZuX3Gg<br />&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp- bConnect : https://www.youtube.com/watch?v=dmDkkvMdnBo<br />&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp- bShopV2 : https://www.youtube.com/watch?v=Wo8bKE2iXaw",
        oldPrice: "155€",
        price: "135€",
        img: AllScript,
        langage: "LUA / HTML / CSS / JS",
        dependance: "ESX Framework",
        link: "/buy/allscript",
        idPaid:"6oE6rb1mjbgT6GcdR2",
        videoUrl: "https://www.youtube.com/embed/Wo8bKE2iXaw?si=kw_LC-NBc790VRU2",
        newScript: false,
        topSeller: false,
    },
    {
        title: "mSquareMenu",
        desc: "Menu Personnel FiveM",
        desc2: "Le mSquareMenu est un script en NUI réalisé en HTML / CSS / JS et LUA. Le design et les fonctionnalités ont été créé par nous même. Ce script à pour but une utilisation centré sur le joueur (inventaire, portefeuille, carte d'identité ...). Possibilité d'ajouter / retirer des catégories sur la boutique.",
        oldPrice: "40€",
        price: "30€",
        img: SquareMenu,
        langage: "LUA / HTML / CSS / JS",
        dependance: "ESX Framework",
        link: "/buy/squaremenu",
        idPaid:"aEU7vfaWTgBdggM5kl",
        videoUrl: "https://www.youtube.com/embed/_t1UwndTugo?si=u9WemEF4y3FoyjN-",
        newScript: false,
        topSeller: false,
    },
    {
        title: "bBoutiqueV2",
        desc: "Boutique V2 FiveM",
        desc2: "La bBoutiqueV2 est un script en NUI réalisé en HTML / CSS / JS et LUA. Le design et les fonctionnalités ont été créé par nous même. Ce script à pour but une utilisation rapide, efficace et intuitive. Possibilité d'ajouter / retirer des catégories sur la boutique.",
        oldPrice: "30€",
        price: "20€",
        img: BoutiqueV2,
        langage: "LUA / HTML / CSS / JS",
        dependance: "ESX Framework",
        link: "/buy/boutiqueV2",
        idPaid:"9AQbLvd5198Le8EcMO",
        videoUrl: "https://www.youtube.com/embed/fzffolnbT5U?si=gXEpT3p4ItsAbysp",
        newScript: false,
        topSeller: false,
    },
    {
        title: "bPolice",
        desc: "Police Job FiveM",
        desc2: "Le bPolice est un script en RageUI réalisé en LUA. Le script a été codé par nous même. Ce script à pour but d'avoir un job police qui fonctionne de façon correct et intuitive. Possibilité d'ajouter / retirer des armes, des véhicules. Possibilité de modifier l'apparence de celui-ci.",
        oldPrice: "18€",
        price: "15€",
        img: Police, 
        langage: "LUA",
        dependance: "ESX Framework",
        link: "/buy/policejob",
        idPaid:"5kA2aV6GD70D2pW28b",
        videoUrl: "https://www.youtube.com/embed/nu3V5NnE6Io?si=nYV6KWwaHroduEG2",
        newScript: false,
        topSeller: false,
    },
    {
        title: "bShopV2",
        desc: "Superette FiveM",
        desc2: " Le bShopV2 est un script en NUI réalisé en Html/Css/Js et Lua. Apparence et Fonctionnalité codé par nous même. Ce script est un script de superette FiveM. Il permet de personnaliser n'importe qu'elle item et de le vendre aux joueurs. La configuration du script a une prise en main simple et intuitive.",
        oldPrice: "20€",
        price: "15€",
        img: ShopV2, 
        langage: "LUA / HTML / CSS / JS",
        dependance: "ESX Framework",
        link: "/buy/shopV2",
        idPaid:"7sIeXH6GD98Ld4A8ww",
        videoUrl: "https://www.youtube.com/embed/Wo8bKE2iXaw?si=kw_LC-NBc790VRU2",
        newScript: true,
        topSeller: false,
    },
    {
        title: "bClothing",
        desc: "Gestion des Vêtement FiveM",
        desc2: "Le bClothing est un script en NUI réalisé en HTML, CSS, Js et LUA. Apparence et Fonctionnalité codé par nous même. Ce script à pour but de permettre au joueur de se changer rapidement et de façon intuitive. Possibilité de modifier l'apparence de celui-ci.",
        oldPrice: "20€",
        price: "15€",
        img: Clothing, 
        langage: "LUA / HTML / CSS / JS",
        dependance: "ESX Framework",
        link: "/buy/clothing",
        idPaid:"cN216R2qn3Or0hObIP",
        videoUrl: "https://www.youtube.com/embed/bjB45fP9FoE?si=5xKMxgTmHE0kPnFN",
        newScript: false,
        topSeller: false,
    },
    {
        title: "mAgentImmo",
        desc: "Job Agent Immobilier FiveM",
        desc2: "Le mAgentImmo est un script en RageUI réalisé en Lua. Apparence et Fonctionnalité codé par nous même.Ce script est un script de job Agent Immobilier.Ce script permet de créer vos propres maisons et appartements et de les vendre aux clients.",
        oldPrice: "30€",
        price: "15€",
        img: AgentImmo, 
        langage: "LUA",
        dependance: "ESX Framework",
        link: "/buy/agentimmo",
        idPaid:"fZeeXH9SPgBd1lS28l",
        videoUrl: "https://www.youtube.com/embed/416peggSdgI?si=O4P7RyZH0VB50FpM",
        newScript: false,
        topSeller: false,
    },
    {
        title: "bBoutique",
        desc: "Boutique FiveM",
        desc2: "La bBoutique est un script en RageUI réalisé en Lua. Apparence et Fonctionnalité codé par nous même. Ce script permet de vendre des véhicules et des armes à vos joueur de façon rapide et intuitive. Le script est liable à Tebex de façon simple et rapide, si vous ne parvenez pas à réussir un support sera fait pour vous aider. Le config du script a une prise en main simple et intuitive.",
        oldPrice: "20€",
        price: "15€",
        img: Boutique, 
        langage: "LUA",
        dependance: "ESX Framework",
        link: "/buy/boutique",
        idPaid:"fZe02N2qn98L8OkeUY",
        videoUrl: "https://www.youtube.com/embed/fzffolnbT5U?si=HGTIWrbwo0PzQy6C",
        newScript: false,
        topSeller: false,
    },
    {
        title: "bLoading",
        desc: "Loading Screen FiveM",
        desc2: "Le bLoading est un script en NUI réalisé en Html, Css, Js et Lua. Apparence et Fonctionnalité codé par nous même. Ce script ce place lors du chargement de votre server lors de chaque connexion de chacun de vos joueurs. Le config du script a une prise en main simple et intuitive.",
        oldPrice: "15€",
        price: "10€",
        img: Loading, 
        langage: "LUA / HTML / CSS / JS",
        dependance: "Aucune",
        link: "/buy/loading",
        idPaid:"8wM6rbd510CfggM8wB",
        videoUrl: "https://www.youtube.com/embed/y-SwfCcaQuQ?si=FAY6bpoBoS6cE98d",
        newScript: false,
        topSeller: false,
    },
    {
        title: "mSociety",
        desc: "Gestion des Société FiveM",
        desc2: "Le mSociety est un script en RageUI réalisé en Lua. Apparence et Fonctionnalité codé par nous même. Ce script est un script de création de gestion de société. Ce script permet de gérer n'impote quelle société (job1)Le config du script a une prise en main simple et intuitive.",
        oldPrice: "15€",
        price: "10€",
        img: Society, 
        langage: "LUA",
        dependance: "ESX Framework",
        link: "/buy/society",
        idPaid:"cN25n75Czfx98Ok7sy",
        videoUrl: "https://www.youtube.com/embed/3Piby0aCAxs?si=sCSe7hvw5SRMYaZy",
        newScript: false,
        topSeller: false,
    },
    {
        title: "bLocationV2",
        desc: "Location de Véhicule V2 FiveM",
        desc2: "Le bLocation V2est un script en NUI réalisé en Html, Css, Js et Lua. Apparence et Fonctionnalité codé par nous même. Intuitive et Agréable, le script donne directement une belle image à votre serveur tant dans le professionnalisme que dans l'expérience de jeu.- Il est entièrement modifiable que ce soit estéthiquement mais également au niveau de son fonctionnement (Véhicules, Images, Couleur ...). La configuration du script a une prise en main simple et intuitive.",
        oldPrice: "10€",
        price: "8€",
        img: LocationV2, 
        langage: "LUA / HTML / CSS / JS",
        dependance: "ESX Framework",
        link: "/buy/locationV2",
        idPaid:"aEU9Dn9SP4Sv8Ok5ks",
        videoUrl: "https://www.youtube.com/embed/EeMz6sw8jXE?si=et5yOq1RGbcPRFZi",
        newScript: false,
        topSeller: false,
    },
    {
        title: "bLargage",
        desc: "Script Annimation Largage de Caisse FiveM",
        desc2: "Le bLargage est un script réalisé en Lua. Apparence et Fonctionnalité codé par nous même. Ce script permet de rajouter des activités à vos joueurs grace au spawn aléatoire des caisses dans la ville. Il est entièrement modifiable que ce soit estéthiquement mais également fonctionnelement. Vous pouvez gérer le temps des spawn mais également les récompenses directement depuis le Config du script qui est simple et intuitif.",
        oldPrice: "10€",
        price: "8€",
        img: Largage, 
        langage: "LUA",
        dependance: "ESX Framework",
        link: "/buy/largage",
        idPaid:"28og1L3ur84H1lS14e",
        videoUrl: "https://www.youtube.com/embed/3toKeZuX3Gg?si=yQcdgPBuomsWo9gy",
        newScript: false,
        topSeller: false,
    },
    {
        title: "bPauseMenu",
        desc: "Pause Menu FiveM",
        desc2: "Le bPauseMenu est un script en NUI réalisé en Html / Css / Js / Lua. Apparence et Fonctionnalité codé par nous même. Ce script remplace le pause menu de base du jeux. Il est entièrement modifiable que ce soit estéthiquement mais également fonctionnelement.",
        oldPrice: "15€",
        price: "8€",
        img: PauseMenu, 
        langage: "LUA / HTML / CSS / JS",
        dependance: "ESX Framework",
        link: "/buy/pausemenu",
        idPaid:"6oEaHr2qnfx92pW14d",
        videoUrl: "https://www.youtube.com/embed/F8x1j2yyqIA?si=cTqhaDv7llR8S73-",
        newScript: false,
        topSeller: false,
    },
    {
        title: "bConnect",
        desc: "Script de Connexion FiveM",
        desc2: "Le bConnect est un script en NUI réalisé en Html, Css, Js et Lua. Apparence et Fonctionnalité codé par nous même. Ce script ce place après le chargement de votre server lors de chaque connexion de chacun de vos joueurs. Le config du script a une prise en main simple et intuitive.",
        oldPrice: "7€",
        price: "5€",
        img: Connect, 
        langage: "LUA / HTML / CSS / JS",
        dependance: "Aucune",
        link: "/buy/connect",
        idPaid:"7sI7vfd515Wz6Gc14f",
        videoUrl: "https://www.youtube.com/embed/dmDkkvMdnBo?si=xmfikXO0K4zm_CLB",
        topSeller: true,
        newScript: false,
    },
];

const ProductCard = ({ product }) => {
    return (
      <div className="group relative bg-black rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition duration-300 m-2 max-w-[345px]">
        <img
          src={product.img}
          alt={product.title}
          className="h-48 w-full object-cover group-hover:scale-105 transition-transform duration-300"
        />
        <div className="absolute inset-0 bg-black bg-opacity-70 flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <h3 className="text-white text-lg font-bold text-center px-2">{product.title}</h3>
          <p className="text-white text-sm text-center px-4">{product.desc}</p>
          <Link to={product.link}><button className="mt-4 bg-yellow-500 text-white px-6 py-2 rounded-full hover:bg-yellow-600 transition">
            Acheter {product.price}
          </button></Link>
        </div>
        {product.topSeller && (
          <span className="absolute top-2 right-2 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded">TOP SELLER</span>
        )}
        {product.newScript && (
          <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded">NEW SCRIPT</span>
        )}
      </div>
    );
};

const MemorysDev = () => {
    const [searchTerm, setSearchTerm] = useState("");

    const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
    <div className="bg-[#FAFAFA] min-h-screen pt-28">
        {/* Barre de recherche et compteur */}
        <div className="max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col justify-center items-center mb-6">
            <h2 className="bg-[#F0F0F0] rounded-full px-8 py-2 text-2xl font-bold text-yellow-500 m-3">
            {filteredProducts.length} <span className='text-gray-800'>Scripts</span>
            </h2>
            <input
            type="text"
            placeholder="Recherchez un produit"
            className="bg-[#F0F0F0] text-gray-500 placeholder-gray-500 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            />
        </div>

        {/* Section des produits */}
        <div className="flex flex-wrap justify-center">
            {filteredProducts.map((product, index) => (
            <ProductCard key={index} product={product} />
            ))}
        </div>
        </div>
    </div>
    );
};

export default MemorysDev;
