import React from "react";
import Header from "./../components/layout/header.jsx";
import backgroundImage from "./../assets/img/wallpaper_home.webp";
import Card from "./../components/elements/card.jsx";
import ContactForm from "./../components/layout/contact.jsx";
import FAQItem from "./../components/elements/faqitem.jsx";
import About from "./../components/elements/about.jsx";
import AboutBis from "./../components/elements/aboutbis.jsx";
import iconCard1 from "./../assets/svg/website.svg";
import iconCard2 from "./../assets/svg/community.svg";
import iconCard3 from "./../assets/svg/ads.svg";

const Home = () => {
  const faqs = [
    {
      question: "Quelles sont les étapes pour la création d’un site web avec votre agence ?",
      answer: "Nous suivons un processus structuré en plusieurs étapes : un premier entretien pour comprendre vos besoins, la définition d’un cahier des charges, la conception d’une maquette, le développement du site, la phase de tests, puis la mise en ligne. Nous assurons également un suivi post-lancement pour les mises à jour ou les ajustements nécessaires."
    },
    {
      question: "Offrez-vous un service de maintenance après la création du site ?",
      answer: "Oui, nous proposons différents forfaits de maintenance pour assurer la mise à jour, la sécurité et l’optimisation continue de votre site web. Cela inclut également la correction de bugs et des ajustements mineurs."
    },
    {
      question: "En quoi consiste le service de community management ?",
      answer: "Le community management consiste à gérer vos réseaux sociaux de manière professionnelle. Nous créons du contenu engageant, interagissons avec votre audience, gérons les commentaires et assurons une veille pour maximiser votre présence en ligne."
    },
    {
      question: "Quels réseaux sociaux gérez-vous ?",
      answer: "Nous gérons une variété de plateformes telles que Facebook, Instagram, Twitter, LinkedIn, TikTok et Pinterest. Nous adaptons nos stratégies à chaque réseau social en fonction de votre secteur d’activité et de votre audience cible."
    },
    {
      question: "Pourquoi investir dans la gestion de campagnes publicitaires ?",
      answer: "La gestion professionnelle des campagnes publicitaires (Facebook Ads, etc.) permet d’optimiser vos dépenses publicitaires, de toucher un public ciblé et d’obtenir un meilleur retour sur investissement (ROI). Nous analysons et ajustons en continu les campagnes pour maximiser vos résultats."
    },
    {
      question: "Quels types de campagnes publicitaires gérez-vous ?",
      answer: "Nous gérons des campagnes sur les réseaux sociaux (Facebook, Instagram), des campagnes display et de remarketing. Chaque stratégie est adaptée aux objectifs de votre entreprise."
    },
  ];
  const cardsData = [
    {
      icon: iconCard1,
      title:"Création de Site Web",
      desc:"Nous créons votre site de A à Z, le design, le développement, la publication et son référencement. Objectif vous créer une présence en ligne professionel qui vous permettra d'avoir une bonne image de marque.",
      link:"/CreationSiteWeb"
    },
    {
      icon: iconCard2,
      title:"Community Management",
      desc:"Nous gérons votre présence sur les réseaux sociaux, en créant une stratégie efficace et du contenu engageant pour renforcer votre image de marque et établir des liens solides avec votre communauté.",
      link:"/CommunityManagement"
    },
    {
      icon: iconCard3,
      title:"Campagne Publicitaire",
      desc:"Nous gérons vos campagnes publicitaires, notament sur les réseaux sociaux (création du visuel video, image, photo), mise en place de la campagne publicitaire en ligne et enfin analyse des résultats.",
      link:"/CampagnePublicitaire"
    }
  ];
  return (
    <div>
      <Header 
        backgroundImage={backgroundImage}
        title="Orazio Consulting,"
        subtitle="Agence de Marketing Digital"
        desc="Une Agence à l'écoute de vos besoins, si vous gagnez nous gagnons !"
        buttonON="true"
      />

      <div className=" bg-[#FAFAFA] pt-20 pb-20">
        <h1 className="text-center items-center text-[#595959] font-bold text-4xl">NOS SERVICES</h1>
        <div className="ml-3 mr-3 mt-16 flex flex-row justify-evenly flex-wrap md:ml-40 md:mr-40">
          {cardsData.map((card, index) => (
            <Card
              key={index}
              icon={card.icon}
              title={card.title}
              desc={card.desc}
              link={card.link}e
            />
          ))}
        </div>
      </div>
      <About />
      <AboutBis />

      <div className="flex flex-col justify-center bg-[#F1F1F1] text-[#595959] p-8 min-h-screen">
        <h1 className="text-4xl font-bold text-center mb-8">F.A.Q</h1>
        <div className="max-w-[20rem] sm:max-w-[30rem] md:max-w-[42rem] md:w-[42rem] text-wrap mx-auto space-y-4">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
      <ContactForm />

    </div>
  );
}

export default Home;