import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const InfosBar = () => {
    const [currentText, setCurrentText] = useState(0);
    const navigate = useNavigate();

    // Liste des messages et leurs liens associés
    const messages = [
        {
            text: "FÊTES DE FIN D'ANNÉE ! -15% avec le code NOEL2024 !",
            link: "/memorysdev", // Lien pour la réduction
        },
        {
            text: "NOUVEAU PRODUIT : Découvrez notre dernière collection !",
            link: "/buy/shopV2", // Lien dynamique pour le nouveau produit
        }
    ];

    // Alterner les messages toutes les 4 secondes
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentText((prevText) => (prevText + 1) % messages.length);
        }, 3000);

        // Nettoyage de l'intervalle
        return () => clearInterval(interval);
    }, [messages.length]);

    // Gestion du clic pour rediriger en fonction du message affiché
    const handleClick = () => {
        navigate(messages[currentText].link);
    };

    return (
        <div
            className="fixed bg-red-500 w-full z-40 h-10 flex text-center items-center justify-center cursor-pointer"
            onClick={handleClick} // Redirection sur clic
        >
            <p className="text-white text-xs md:text-sm font-semibold">{messages[currentText].text}</p>
        </div>
    );
};

export default InfosBar;
