import React from 'react';
import Button from '../components/elements/button';
import infosicon from "./../assets/svg/infos.svg";
import codeicon from "./../assets/svg/code.svg";
import depicon from "./../assets/svg/dependence.svg";
import { products } from './memorysdev';
import { Link } from 'react-router-dom';

const ProductPage = ({ product }) => {
    // Filtrer les scripts "Top Seller" et "New Script"
    const topSellers = products.filter(p => p.topSeller && p.idPaid !== product.idPaid);
    const newScripts = products.filter(p => p.newScript && p.idPaid !== product.idPaid);
  
  return (
    <div className="bg-white min-h-screen text-gray-800 pt-20">
      {/* Contenu principal */}
      <div className="max-w-6xl mx-auto p-6 flex flex-col lg:flex-row lg:space-x-6 mt-8">
        {/* Section vidéo et description */}
        <div className="flex-1 bg-[#F0F0F0] rounded-lg p-6">
          {/* Vidéo de démonstration */}
          <div className="mb-6">
            <iframe
              src={product.videoUrl || "https://www.youtube.com/embed/your-video-id"}
              title={`Vidéo de présentation - ${product.title}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full aspect-video rounded-lg"
            ></iframe>
          </div>

          {/* Description principale */}
          <h2 className="text-3xl font-bold mb-4">Description</h2>
          <div className="space-y-4">
            {/* Fonctionnalités */}
            <div>
              <h3 className="text-xl font-bold flex items-center">
                <span className="material-icons text-yellow-500 mr-2"><img src={infosicon} alt="icon" className="h-6 w-6" /></span>
                CARACTÉRISTIQUES
              </h3>
              <p className="text-gray-600" dangerouslySetInnerHTML={{ __html: product.desc2 }}/>
            </div>

            {/* Langages utilisés */}
            <div>
              <h3 className="text-lg font-bold flex items-center">
                <span className="material-icons text-yellow-500 mr-2"><img src={codeicon} alt="icon" className="h-7 w-7" /></span>
                LANGAGES UTILISÉS     
                <div className="border-l-2 border-gray-400 h-4 mx-4"></div>
                <span className="font-normal text-gray-600">{product.langage}</span>
              </h3>
            </div>

            {/* Dépendances */}
            <div>
              <h3 className="text-lg font-bold flex items-center">
                <span className="material-icons text-yellow-500 mr-2"><img src={depicon} alt="icon" className="h-6 w-6" /></span>
                DÉPENDANCES
                <div className="border-l-2 border-gray-400 h-4 mx-4"></div>
                <span className="font-normal text-gray-600">{product.dependance}</span>
              </h3>
            </div>
          </div>

          {/* Note légale */}
          <p className="text-sm text-gray-500 mt-4">
          Ce script a été développé par  <span className="text-yellow-500">{product.author || "BarWoz"}</span>.<br />
            Cette ressource est exclusivement disponible à la vente sur <span className="text-yellow-500">orazio-consulting.fr</span>.<br />
            La revente ou la distribution non autorisée sont strictement interdites.
          </p>
        </div>
        <div>
           {/* Section prix et bouton acheter */}
          <div className="w-full bg-[#F0F0F0] max-h-[200px] rounded-lg p-6 flex flex-col items-center justify-center mt-6 lg:mt-0">
            <h3 className="text-lg text-yellow-500 font-extralight uppercase mb-1">Scripts</h3>
            <h2 className="text-2xl font-bold text-gray-800 mb-1">{product.title}</h2>

            {/* Conteneur de prix avec une barre verticale entre les deux */}
            <div className="flex items-center mb-4 space-x-4 bg-[#FAFAFA] rounded-full px-5 py-1">
              {/* Prix original barré */}
              <div className="flex items-center">
                <p className="text-red-500 line-through text-lg font-bold">
                  {product.oldPrice || "...."} {/* Prix original barré */}
                </p>
              </div>

              {/* Barre verticale */}
              <div className="border-l-2 border-gray-400 h-4 mx-4"></div>

              {/* Nouveau prix avec fond vert */}
              <div className="flex items-center">
                <p className="text-green-600 text-lg font-bold">
                  {product.price || "...."} {/* Nouveau prix */}
                </p>
              </div>
            </div>
            
            {/* Bouton centré */}
            <div className="w-full bg-[#F0F0F0] text-center">
              <Button text="ACHETER" link={`https://buy.stripe.com/${product.idPaid}`} />
            </div>
          </div>

          {/* Section des autres scripts */}
          <div className="w-full bg-[#F0F0F0] rounded-lg h-auto mt-6 p-6 flex flex-col">
            {/* Top Seller Scripts */}
            {topSellers.length > 0 && (
              <div
                className={`${
                  newScripts.length > 0 ? "mb-6" : ""
                }`}
              >
                <div className="flex flex-wrap">
                  {topSellers.map((script, index) => (
                    <div
                      key={index}
                      className="group relative bg-black rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition duration-300"
                    >
                      <img
                        src={script.img}
                        alt={script.title}
                        className="h-48 w-full object-cover group-hover:scale-105 transition-transform duration-300"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-70 flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <h3 className="text-white text-lg font-bold">{script.title}</h3>
                        <p className="text-white text-sm">{script.desc}</p>
                        <button className="mt-4 bg-yellow-500 text-white px-6 py-2 rounded-full hover:bg-yellow-600 transition">
                          <Link to={script.link}>Acheter {script.price}</Link>
                        </button>
                      </div>
                      <span className="absolute top-2 right-2 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded">TOP SELLER</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* New Scripts */}
            {newScripts.length > 0 && (
              <div>
                <div className="flex flex-wrap">
                  {newScripts.map((script, index) => (
                    <div
                      key={index}
                      className="group relative bg-black rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition duration-300"
                    >
                      <img
                        src={script.img}
                        alt={script.title}
                        className="h-48 w-full object-cover group-hover:scale-105 transition-transform duration-300"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-70 flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <h3 className="text-white text-lg font-bold">{script.title}</h3>
                        <p className="text-white text-sm">{script.desc}</p>
                        <button className="mt-4 bg-yellow-500 text-white px-6 py-2 rounded-full hover:bg-yellow-600 transition">
                          <Link to={script.link}>Acheter {script.price}</Link>
                        </button>
                      </div>
                      <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded">NEW SCRIPT</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
