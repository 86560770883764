import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { products } from './pages/memorysdev.jsx';

// Import des pages avec les bons chemins
import Home from './pages/home.jsx';
import Services from './pages/services.jsx';
import MemorysDev from './pages/memorysdev.jsx';
import FAQ from './pages/faq.jsx';
import Contact from './pages/contact.jsx';
import CreationSiteWeb from './pages/creation-siteweb.jsx';
import CommunityManagement from './pages/community-management.jsx';
import CampagnePublicitaire from './pages/campagne-publicitaire.jsx';
import MentionsLegales from './pages/mentions-legales.jsx';
import Confidentialite from './pages/confidentialite.jsx';
import ConditionUtilisation from './pages/condition-utilisation.jsx';
import ProductPage from './pages/productspage.jsx';

// Import des composants layout
import Navbar from './components/layout/navbar.jsx';
import InfosBar from './components/layout/infosbar.jsx';
import Footer from './components/layout/footer.jsx';
import ScrollToTop from './components/elements/scrollToTop.jsx';

function App() {
  const location = useLocation(); // Hook pour obtenir la localisation actuelle

  // Conditionner l'affichage de la classe mt-10
  const navbarClass = (location.pathname === '/memorysdev' || location.pathname.startsWith('/buy/')) ? 'mt-10' : '';

  // Vérifier si nous sommes sur une page produit dynamique (comme /buy/pausemenu)
  const isProductPage = location.pathname.startsWith('/buy/');

  return (
    <div>
      <ScrollToTop />
      
      {/* Afficher InfosBar uniquement sur /memorysdev et /buy/:productId */}
      {location.pathname === '/memorysdev' || isProductPage ? (
        <InfosBar />
      ) : null}

      {/* Appliquer mt-10 uniquement sur /memorysdev et /buy/:productId */}
      <Navbar className={navbarClass} />
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/memorysdev" element={<MemorysDev />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/creation-siteweb" element={<CreationSiteWeb />} />
        <Route path="/community-management" element={<CommunityManagement />} />
        <Route path="/campagne-publicitaire" element={<CampagnePublicitaire />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/confidentialite" element={<Confidentialite />} />
        <Route path="/condition-utilisation" element={<ConditionUtilisation />} />

        {/* Route dynamique pour les pages produits */}
        <Route path="/buy/:productId" element={<ProductPage />} />

        {products.map((product) => (
          <Route
            key={product.id}
            path={product.link.toLowerCase()} // Assurez-vous que le lien est en minuscules
            element={<ProductPage product={product} />}
          />
        ))}
      </Routes>

      <Footer />
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
