import React from "react";
import Header from "../components/layout/header.jsx";
import backgroundImage from "./../assets/img/wallpaper_home.webp";
import FAQItem from "./../components/elements/faqitem.jsx";

const FAQ = () => {
    const faqs = [
        {
            question: "Quelles sont les étapes pour la création d’un site web avec votre agence ?",
            answer: "Nous suivons un processus structuré en plusieurs étapes : un premier entretien pour comprendre vos besoins, la définition d’un cahier des charges, la conception d’une maquette, le développement du site, la phase de tests, puis la mise en ligne. Nous assurons également un suivi post-lancement pour les mises à jour ou les ajustements nécessaires."
        },
        {
            question: "Quels types de sites web pouvez-vous créer ?",
            answer: "Nous créons des sites vitrines, des sites e-commerce, des blogs, ainsi que des plateformes sur mesure adaptées à vos besoins spécifiques. Nous nous assurons que chaque site est responsive, c’est-à-dire qu’il est optimisé pour tous les appareils (ordinateurs, tablettes, smartphones)."
        },
        {
            question: "Combien de temps faut-il pour créer un site web ?",
            answer: "La durée de création dépend de la complexité du projet. En général, un site vitrine simple peut prendre entre 4 et 6 semaines, tandis qu’un site plus complexe comme un e-commerce peut nécessiter jusqu’à 10 semaines ou plus."
        },
        {
            question: "Quels sont les coûts associés à la création d’un site web ?",
            answer: "Les tarifs varient en fonction de la taille, des fonctionnalités et du niveau de personnalisation souhaité. Nous proposons des devis sur mesure pour chaque projet afin de garantir une transparence totale."
        },
        {
            question: "Offrez-vous un service de maintenance après la création du site ?",
            answer: "Oui, nous proposons différents forfaits de maintenance pour assurer la mise à jour, la sécurité et l’optimisation continue de votre site web. Cela inclut également la correction de bugs et des ajustements mineurs."
        },
        {
            question: "Quelles plateformes utilisez-vous pour le développement de sites web ?",
            answer: "Nous travaillons avec plusieurs plateformes comme WordPress, Shopify, et d'autres systèmes de gestion de contenu (CMS), ainsi que des solutions de développement sur mesure selon les besoins spécifiques de chaque client."
        },
        {
            question: "Pourquoi investir dans la gestion de campagnes publicitaires ?",
            answer: "La gestion professionnelle des campagnes publicitaires (Facebook Ads, etc.) permet d’optimiser vos dépenses publicitaires, de toucher un public ciblé et d’obtenir un meilleur retour sur investissement (ROI). Nous analysons et ajustons en continu les campagnes pour maximiser vos résultats."
        },
        {
            question: "Quels types de campagnes publicitaires gérez-vous ?",
            answer: "Nous gérons des campagnes sur les réseaux sociaux (Facebook, Instagram), des campagnes display et de remarketing. Chaque stratégie est adaptée aux objectifs de votre entreprise."
        },
        {
            question: "Comment mesurez-vous le succès d’une campagne publicitaire ?",
            answer: "Nous utilisons des indicateurs clés de performance (KPI) comme le coût par clic (CPC), le taux de conversion, le retour sur investissement publicitaire (ROAS) et l’engagement des utilisateurs. Un reporting régulier vous est fourni pour suivre les performances en temps réel."
        },
        {
            question: "Quel budget publicitaire est nécessaire pour commencer une campagne ?",
            answer: "Il n’y a pas de montant fixe, cela dépend de vos objectifs et de la portée que vous souhaitez atteindre. Nous vous conseillons toutefois un budget minimum en fonction de la plateforme utilisée afin de garantir des résultats significatifs."
        },
        {
            question: "En quoi consiste le service de community management ?",
            answer: "Le community management consiste à gérer vos réseaux sociaux de manière professionnelle. Nous créons du contenu engageant, interagissons avec votre audience, gérons les commentaires et assurons une veille pour maximiser votre présence en ligne."
        },
        {
            question: "Quels réseaux sociaux gérez-vous ?",
            answer: "Nous gérons une variété de plateformes telles que Facebook, Instagram, Twitter, LinkedIn, TikTok et Pinterest. Nous adaptons nos stratégies à chaque réseau social en fonction de votre secteur d’activité et de votre audience cible."
        },
        {
            question: "Pourquoi devrais-je externaliser mon community management ?",
            answer: "Externaliser cette tâche vous permet de bénéficier d’une expertise professionnelle sans mobiliser vos ressources internes. Cela garantit une gestion cohérente de votre image de marque, une stratégie de contenu optimisée et une interaction constante avec votre communauté."
        },
        {
            question: "Comment créez-vous du contenu pour mes réseaux sociaux ?",
            answer: "Nous travaillons en étroite collaboration avec vous pour comprendre votre marque et vos objectifs. Nous élaborons ensuite une stratégie de contenu adaptée, qui inclut des publications régulières, des visuels attractifs et des textes optimisés pour susciter l’engagement de votre audience."
        },
        {
            question: "Proposez-vous des rapports sur les performances des réseaux sociaux ?",
            answer: "Oui, nous fournissons des rapports détaillés sur les performances de vos réseaux sociaux, incluant des données sur l’engagement, la croissance de la communauté, le trafic généré et d’autres indicateurs pertinents pour évaluer la réussite de votre stratégie."
        }
    ];
    return (
        <div>
            <Header 
                backgroundImage={backgroundImage}
                title="F.A.Q"
                desc="Vous avez des questions ? Nous avons les réponses !"
                buttonON="true"
            />
            <div className="flex flex-col justify-center bg-[#FAFAFA] text-[#595959] p-8 min-h-screen">
                <h1 className="text-4xl font-bold text-center mb-8">F.A.Q</h1>
                <div className="max-w-[20rem] sm:max-w-[30rem] md:max-w-[42rem] md:w-[42rem] mx-auto space-y-4">
                {faqs.map((faq, index) => (
                    <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
                </div>
            </div>
        </div>
    ); 
}

export default FAQ;