import Header from '../components/layout/header.jsx'
import backgroundImage from "./../assets/img/wallpaper_home.webp";
import Button from '../components/elements/button.jsx'; // Assurez-vous que le bouton est bien importé

const AdsService = () => {
    return (
        <div className='bg-[#FAFAFA]'>
          {/* Header avec l'image de fond */}
          <Header 
            backgroundImage={backgroundImage}
            title="Campagnes Publicitaires"
            desc="Propulsez votre marque avec des campagnes publicitaires digitales performantes et ciblées. Augmentez vos conversions et atteignez de nouveaux clients."
            buttonON="true"
          />
    
          {/* Section présentation du service */}
          <section className="container mx-auto py-16 px-4">
            {/* Explication détaillée */}
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">Des campagnes publicitaires sur mesure</h2>
              <p className="text-lg text-gray-600 leading-relaxed max-w-4xl mx-auto">
                Nos campagnes publicitaires sont conçues pour maximiser votre retour sur investissement. Que vous souhaitiez augmenter la notoriété de votre marque, 
                générer des leads ou stimuler vos ventes, nous créons des publicités ciblées et performantes sur des plateformes telles que Facebook, Instagram, et bien plus.
                Nous analysons en profondeur les données pour optimiser constamment vos campagnes et garantir les meilleurs résultats. 
                Travaillez avec une équipe dédiée qui connaît les tendances du marché et sait comment toucher votre audience efficacement.
              </p>
            </div>
    
            {/* Images illustratives avec descriptions */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
              <div className="relative">
                <img 
                  src="https://via.placeholder.com/600x400" 
                  alt="Publicité sur les réseaux sociaux"
                  className="rounded-lg shadow-lg object-cover w-full"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                  <p className="text-white text-xl font-semibold">Publicité sur les réseaux sociaux</p>
                </div>
              </div>
              <div className="relative">
                <img 
                  src="https://via.placeholder.com/600x400" 
                  alt="Campagnes Google Ads performantes"
                  className="rounded-lg shadow-lg object-cover w-full"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                  <p className="text-white text-xl font-semibold">Analyse des performances</p>
                </div>
              </div>
            </div>
    
            {/* Points clés du service */}
            <div className="text-center mb-16">
              <h3 className="text-2xl font-semibold text-gray-800 mb-6">Pourquoi choisir nos campagnes publicitaires ?</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="bg-[#F1F1F1] p-6 rounded-lg shadow-sm">
                  <h4 className="text-xl font-bold mb-4">Ciblage précis</h4>
                  <p className="text-gray-600">Nous analysons votre audience pour définir un ciblage ultra-précis, garantissant des publicités vues par les bonnes personnes.</p>
                </div>
                <div className="bg-[#F1F1F1] p-6 rounded-lg shadow-sm">
                  <h4 className="text-xl font-bold mb-4">Optimisation continue</h4>
                  <p className="text-gray-600">Grâce à des analyses et ajustements réguliers, nous optimisons vos campagnes pour maximiser les conversions et minimiser les coûts.</p>
                </div>
                <div className="bg-[#F1F1F1] p-6 rounded-lg shadow-sm">
                  <h4 className="text-xl font-bold mb-4">Rapport détaillé</h4>
                  <p className="text-gray-600">Recevez des rapports clairs et détaillés sur la performance de vos campagnes, pour une transparence totale et des décisions éclairées.</p>
                </div>
              </div>
            </div>
    
            {/* Call to Action section */}
            <div className="text-center py-16 bg-[#F1F1F1] text-gray-600 rounded-lg shadow-sm">
                <h3 className="text-3xl text-gray-800 font-semibold mb-6">Prêt à lancer votre campagne publicitaire ?</h3>
                <p className="text-lg mb-8 ml-8 mr-8 text-gray-500 ">
                    Contactez-nous pour discuter de vos objectifs publicitaires et découvrir comment nous pouvons vous aider à les atteindre avec des solutions sur mesure.
                </p>
                <Button text="NOUS CONTACTER" link="/contact" />
            </div>
          </section>
        </div>
      );
}

export default AdsService;