import React from "react";
import ServicesPages from "../components/elements/servicespages.jsx";
import backgroundImage1 from "./../assets/img/website_service.webp";
import backgroundImage2 from "./../assets/img/management-service.webp";
import backgroundImage3 from "./../assets/img/adds_service.webp";

const sections = [
    {
      title: "CRÉATION DE SITE WEB",
      buttonLabel: "DÉCOUVRIR",
      buttonLink: "/creation-siteweb",
      imageUrl: backgroundImage1,
    },
    {
      title: "COMMUNITY MANAGEMENT",
      buttonLabel: "DÉCOUVRIR",
      buttonLink: "/community-management",
      imageUrl: backgroundImage2,
    },
    {
      title: "CAMPAGNE PUBLICITAIRE",
      buttonLabel: "DÉCOUVRIR",
      buttonLink: "/campagne-publicitaire",
      imageUrl: backgroundImage3,
    },
];

const Services = () => {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-3">
            {sections.map((section, index) => (
                <ServicesPages
                key={index}
                title={section.title}
                buttonLabel={section.buttonLabel}
                buttonLink={section.buttonLink}
                imageUrl={section.imageUrl}
                />
             ))}
        </div>
    );
}

export default Services;