import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from './../../assets/img/Orazio_Consulting_Logo.webp';
import Button from './../elements/button.jsx';

const Navbar = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
      <nav className={`${className} fixed bg-[#FAFAFA] w-full z-10 shadow-xl`}>
        <div className="container mx-auto flex justify-around items-center">
          {/* Logo */}
          <img src={Logo} alt="Orazio Consulting" className="w-36" />

          {/* Navigation links centered between logo and contact button */}
          <ul className="hidden lg:flex space-x-8 items-center flex-grow justify-center">
            <li>
              <Link to="/" className="text-[#565656] transition-all hover:text-[#f4b814]">Home</Link>
            </li>
            <li>
              <Link to="/services" className="text-[#565656] transition-all hover:text-[#f4b814]">Services</Link>
            </li>
            <li>
            <Link to="/memorysdev" className="text-[#565656] transition-all hover:text-[#f4b814]">Memory's</Link>
            </li>
            <li>
              <Link to="/faq" className="text-[#565656] transition-all hover:text-[#f4b814]">F.A.Q</Link>
            </li>
          </ul>

          {/* Button "Nous Contacter" only on desktop */}
          <div className="hidden lg:block">
            <Button text="NOUS CONTACTER" link="/contact" />
          </div>

          {/* Hamburger Menu for mobile */}
          <div className="lg:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-800 focus:outline-none"
            >
              {/* Hamburger animation */}
              <svg
                className={`w-6 h-6 transform transition-transform duration-300 ${isOpen ? 'rotate-45' : 'rotate-0'}`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        {isOpen && (
          <div className={`lg:hidden bg-[#FAFAFA] w-full transform transition-transform duration-500 ease-in-out ${isOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'} overflow-hidden`}>
            <ul className="space-y-4 px-4 py-4 flex flex-col justify-center items-center">
              <li>
                <Link to="/" onClick={toggleMenu} className="text-[#565656] block transition-all hover:text-[#f4b814]">Home</Link>
              </li>
              <li>
                <Link to="/services" onClick={toggleMenu} className="text-[#565656] block transition-all hover:text-[#f4b814]">Services</Link>
              </li>
              <li>
                <Link to="/memorysdev" onClick={toggleMenu} className="text-[#565656] transition-all hover:text-[#f4b814]">Memory's</Link>
              </li>
              <li>
                <Link to="/faq" onClick={toggleMenu} className="text-[#565656] block transition-all hover:text-[#f4b814]">F.A.Q</Link>
              </li>
              <li>
                <Button text="NOUS CONTACTER" link="/contact" />
              </li>
            </ul>
          </div>
        )}
      </nav>

  );
};

export default Navbar;
