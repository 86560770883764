import React from "react";
import Header from "./../components/layout/header.jsx";
import backgroundImage from "./../assets/img/wallpaper_home.webp";

const MentionsLegales = () => {
    return (
        <div>
            <Header 
                backgroundImage={backgroundImage}
                title="MENTIONS LÉGALES"
            />

            <div className="mt-32 ml-80 mr-80 mb-32 text-justify">
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">1. Présentation du site</h2>
                    <p className="mb-3">
                    En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site orazio-consulting.fr l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
                    </p>
                    <ul>
                        <li className="ml-10"><strong> • Propriétaire :</strong> ORAZIO Consulting EI – Siret N° 982 419 830 000 12 – 149 Cours de la marne, 33800 Bordeaux, France</li>
                        <li className="ml-10"><strong> • Créateur :</strong> Baptiste ORAZIO</li>
                        <li className="ml-10"><strong> • Responsable publication :</strong> ORAZIO Consulting EI – baptiste.orazio@orazio-consulting.fr</li>
                        <p className="mb-3 mt-3">Le responsable publication est une personne physique ou une personne morale.</p>
                        <li className="ml-10"><strong> • Webmaster :</strong> Baptiste ORAZIO – baptiste.orazio@orazio-consulting.fr</li>
                        <li className="ml-10"><strong> • Hébergeur :</strong> LWS (Ligne Web Services) – 10 Rue Penthièvre, 75008 Paris, France - Enregistré au RCS de Paris sous le numéro 851 993 683.</li>
                        <li className="ml-10"><strong> • Crédits :</strong> Baptiste ORAZIO </li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">2. Limitations contractuelles sur les données techniques</h2>
                    <p className="mb-2">
                    Le site utilise les technologies HTML, CSS et JavaScript.
                    </p>
                    <p>
                    Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis à jour.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">3. Propriété intellectuelle et contrefaçons</h2>
                    <p className="mb-2">
                    La société <strong>ORAZIO Consulting</strong> est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.
                    </p>
                    <p className="mb-2">
                    Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de la société <strong>ORAZIO Consulting</strong>.
                    </p>
                    <p>
                    Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                    </p>
                </section>
           
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">4. Limitations de responsabilité</h2>
                    <p className="mb-2">
                    La société <strong>ORAZIO Consulting</strong> ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site <strong>orazio-consulting.fr</strong>, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité.
                    </p>
                    <p className="mb-2">
                    La société <strong>ORAZIO Consulting</strong> ne pourra également être tenue responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site <strong>orazio-consulting.fr</strong>.
                    </p>
                    <p>
                    Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont à la disposition des utilisateurs. La société <strong>ORAZIO Consulting</strong> se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">5. Liens hypertextes et cookies</h2>
                    <p className="mb-2">
                    Le site <strong>orazio-consulting.fr</strong> contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de la société <strong>ORAZIO Consulting</strong>. Cependant, la société <strong>ORAZIO Consulting</strong> n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.
                    </p>
                    <p>
                    La navigation sur le site <strong>orazio-consulting.fr</strong> est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">6. Droit applicable et attribution de juridiction</h2>
                    <p>
                    Tout litige en relation avec l’utilisation du site <strong>orazio-consulting.fr</strong> est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de <strong>Bordeaux</strong>.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">7. Les principales lois concernées</h2>
                    <ul>
                        <li className="mb-2">Loi <strong>n° 78-17</strong> du 6 janvier 1978, notamment modifiée par la loi <strong>n° 2004-801</strong> du 6 août 2004 relative à l’informatique, aux fichiers et aux libertés.</li>
                        <li>Loi <strong>n° 2004-575</strong> du 21 juin 2004 pour la confiance dans l’économie numérique.</li>
                    </ul>
                </section>

                <section>
                    <h2 className="text-2xl font-semibold mb-4">11. Lexique</h2>
                    <ul>
                        <li className="mb-2"><strong>Utilisateur :</strong> Internaute se connectant, utilisant le site susnommé.</li>
                        <li><strong>Informations personnelles :</strong> « les informations qui permettent, sous quelque forme que ce soit, directement ou non, l’identification des personnes physiques auxquelles elles s’appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).</li>
                    </ul>
                </section>
            </div>
        </div>
    );
}

export default MentionsLegales;